.my-navbar-css {
    /* background-image: linear-gradient(-100deg,#8a00d4, #fff); */
    background-image: linear-gradient(-100deg, #fff, #fff);
}

.hearder-text {
    /* color: #ffffff !important; */
    color: #000 !important;
}

.nav-menu-right {
    margin-left: auto;
    margin-right: 0;
}

.navbar {
    padding: 0px;
    height: 50px;
}

.navbar-brand {
    width: 10%;
}

.submenu-title-wrapper {
    margin-left: 5px;
}

.ant-menu-submenu-popup .submenu-title-wrapper {
    font-weight: initial;
    font-size: 16px;
}

.child-menu-top {
    color: rgba(0, 0, 0, 0.65);
    margin-top: 8px;
}

.ant-menu-horizontal > .ant-menu-item > a {
    display: initial !important;
}
.ant-badge{
    margin-bottom: 12px !important;
}
.ant-scroll-number-only-unit {
    color: #fff;
}