.table-striped tbody tr:nth-of-type(odd){
    background-color: #f2f8f9 !important;
}
.table table tbody {
    color: #212529;
}
.table table thead {
    color: #212529;
}
.ant-pagination-item a {
    padding:  4px 6px !important;
}
.ant-pagination-item-link .anticon {
    padding-top: 9px;
}
.ant-table-thead > tr > th {
    font-weight: 600 !important;
    vertical-align: middle !important;

}
.ant-table-tbody > tr > td {
    vertical-align: middle !important;

}
.ant-table-title-total{
    font-weight: 600 !important;
}
.ant-table-title{
    color: #212529;
}
.css_agency_name{
    min-width: 300px !important;
} 
table tr:nth-child(2n) td {
    background-color: #f2f8f9;
}