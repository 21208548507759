#body-row {
    margin-left: 0;
    margin-right: 0;
}

#root{
    font-size: 14px;
}

#sidebar-container {
    height: 100%;
    padding: 0;
    background-color: #fafafa;
    box-shadow: inset -1px 0 0 #e5e5e5;
    transform: translate3d(0, 0, 0);
    transition: width 0.1s, left 0.3s;
}
#body-row{
    min-height: 94vh;
}

/* Sidebar sizes when expanded and expanded */

.sidebar-collapsed {
    width: 60px;
}

/* Menu item*/
#sidebar-container .list-group a {
    height: 50px;
    color: #2e2e2e;
    line-height: 1.5;
    font-weight: 600;
    font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Noto Sans", Ubuntu, Cantarell, "Helvetica Neue", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"
}

.selected-link {
    background-color: #1890ff !important;
    color: #f2f8f9!important;
}

.selected-link:hover{
    background-color: #1890ff !important;
    color: #212529!important;
}

/* Submenu item*/
#sidebar-container .list-group .sidebar-submenu a {
    height: 45px;
    padding-left: 30px;
}

.sidebar-submenu {
    font-size: 0.9rem;
}

/* Separators */
.sidebar-separator-title {
    background-color: #333;
    height: 35px;
}

.sidebar-separator {
    background-color: #333;
    height: 25px;
}

.logo-separator {
    background-color: #333;
    height: 60px;
}

/* Closed submenu icon */
#sidebar-container .list-group .list-group-item[aria-expanded="false"] .submenu-icon::after {
    content: " \f0d7";
    font-family: FontAwesome;
    display: inline;
    text-align: right;
    padding-left: 10px;
}

/* Opened submenu icon */
#sidebar-container .list-group .list-group-item[aria-expanded="true"] .submenu-icon::after {
    content: " \f0da";
    font-family: FontAwesome;
    display: inline;
    text-align: right;
    padding-left: 10px;
}

.pointer {
    cursor: pointer !important;
}

.main-content {
    background-color: #f2f8f9;
}

.main-content>.card {
    border: 0;
    padding: 20px;
}

.main-content>.card>.card-body {
    padding: 0;
    padding-top: 10px;
}

.card-title1 {
    font-family: Arial, Helvetica, sans-serif;
    text-transform: uppercase !important;
    font-weight: 700;
    color: #212529;
    margin-bottom: 20px;
    font-size: 25px;
    text-transform: capitalize;
}

.nopadding-left {
    padding-left: 0 !important;
}

.form-control:focus {
    outline: none;
    outline-width: 0;
    outline-color: transparent;
    box-shadow: none;
    outline-style: none;
    color: #495057;
    background-color: #fff;
    border-color: #80bdff !important;
}
.search-form-index .form-group{
    margin-bottom: 0px;
}
.card-dashboard{
    flex-direction: row;
}
td.column-money {
    text-align: right !important;
}

.ant-radio-wrapper{
    margin-right: 2em !important;
}

.ant-radio-disabled + span {
    color: black !important;
}

.ant-card-bordered {
    border: 1px solid #e8e8e8 !important;
    margin-bottom: 20px !important;
}

.th-stt{
    width: 5%;
}

.ant-input[disabled]{
    color: black !important;
}

.ant-select-disabled {
    color: black !important;
}
.check-box-group .ant-checkbox-wrapper{
    margin: 1em 0 2em 1em;
}

.check-box-group .ant-checkbox-wrapper  + .ant-checkbox-wrapper{
    margin-left: 3em;
}
.ant-form-item {
    margin-bottom: 0px !important;
}
.gen-backgroud{
    width: 60%;
    margin: auto;
  }
  .table-gen{
    width: 100%;
  }
  .float-right{
    float: right;
  }
  
  .fieldset1 {
    align-self: center;
    width: 80%;
    border:2px solid black;
    border-radius:8px;
    margin: auto;
    padding: 1em;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #212529;
  }
  .gen-backgroud .legend1 {
    margin: 0;
    width: 4em;
    padding-left: 5px;
    border-bottom: 0;
    font-size: 20px;
  }
  .fname{
    margin-left: 2em;
    width: 7em;
    padding-top: 0.7em;
  }
  .row-result{
    display: inline-flex;
    padding: 0 1em 0 1em;
    height: 3em;
    width: 100%;
  }
  .row-result input{
    border: hidden;
  }
  .button-result{
    justify-content: center!important;
    padding: 2em 0 2em 0;
  }
  .right{
    margin-left: 11em;
  }
  .height-5em{
    height: 5em;
  }
  .modal-pin{
    width: 50%;
    margin: auto;
  }
  .ant-calendar-picker {
      width: 100%;
  }
.drop-contract:hover {
    background-color:#34495e;
}
.ant-table-placeholder{
    z-index: -1 !important;
}
th{
    text-align: center !important;
}
.fname{
    padding-top: 0.7em;
    text-align: left;
}
.nopadding-top{
    margin-top: 0;
    width: 60%;
    text-align: left;
}
.row-result{
    display: inline-flex;
    padding: 0 1em 0 1em;
    height: 3em;
    width: 100%;
}
.row-result input{
    border: hidden;
}
.row-result textarea{
    border: hidden;
    resize: none;
}
.button-result{
    justify-content: center!important;
    padding: 1em 0 1em 0;
}
.gen-backgroud .legend {
    margin: 0;
    width: 4em;
    padding-left: 5px;
    border-bottom: 0;
    font-size: 20px;
    text-align: left;
}
.fieldset {
    align-self: center;
    border:2px solid black;
    border-radius:8px;
    margin: auto;
    padding: 1em;
    font-family: Arial, Helvetica, sans-serif;
    font-size: 14px;
    color: #212529;
}
.mr-top{
    margin-top: 1em;
}
.select-type-doc p{
    margin-bottom: 0 !important;
}
.select-type-doc .p-1{
    padding: 0 !important;
}
.ant-popover-inner-content{
    white-space: pre-wrap;
}
.m-w-30{
    width: 30% !important;
}
.btn-submit-form.right{
    height: fit-content;
    margin-top: 1.25em;
}
.custom-file-noWrap{
    white-space: nowrap;
    overflow: hidden;
}